@media screen and (max-width: 767px) {
	.top_container {
		.title {
			font-size: 16px;
			font-weight: 600;
			margin: 10px;
		}
	
		.karuta_items_container {
		
			.karuta_item {	
				width: 90%;
				border: solid 1px #000;
				border-radius: 10px;
				padding: 10px;
				margin: 10px;
				display: flex;
	
				img {
					width: 200px;
				}
	
				.karuta_info_container {
					text-align: left;
					font-size: 12px;
					width: 100%;
	
					.text {
	
					}
	
					.name {
	
					}
	
					.create_at {
	
					}

					.status {
						.show {
							color: red;
						}

						.hide {
							color: #999;
						}
					}
	
					button {
						width: 100%;
						margin: 10px auto;
						background-color: #FFF;
						border: solid 1px #999;
						border-radius: 10px;
					}
				}
			}
	
			.bottom_text {
				width: 90%;
				height: 50px;
				border: solid 1px #999;
				color: #999;
				border-radius: 10px;
				margin: 10px;
				padding: 10px;
				text-align: center;
				line-height: 50px;
	
				&.load {
					border: solid 1px #000;
					color: #000;
	
					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.top_container {
		.title {
			font-size: 20px;
			font-weight: 600;
			margin: 10px;
		}
	
		.karuta_items_container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		
			.karuta_item {	
				width: 200px;
				height: 500px;
				border: solid 1px #000;
				border-radius: 10px;
				padding: 10px;
				margin: 10px;
	
				img {
					width: 200px;
				}
	
				.karuta_info_container {
					text-align: left;
					font-size: 12px;
	
					.text {
	
					}
	
					.name {
	
					}
	
					.create_at {
	
					}
					
					.status {
						.show {
							color: red;
						}

						.hide {
							color: #999;
						}
					}
	
					button {
						width: 100%;
						margin: 10px auto;
						background-color: #FFF;
						border: solid 1px #999;
						border-radius: 10px;
					}
				}
			}
	
			.bottom_text {
				width: 200px;
				height: 500px; 
				border: solid 1px #999;
				color: #999;
				border-radius: 10px;
				margin: 10px;
				padding: 10px;
				text-align: center;
				line-height: 500px;
	
				&.load {
					border: solid 1px #000;
					color: #000;
	
					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}
}
