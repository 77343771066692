@media screen and (max-width: 767px) {
	.header {
		width: 100%;
		height: auto;
		border-bottom: solid 1px #999;
		display: flex;
		flex-basis: auto;
	
		.title {
			color: #000;
			font-weight: 600;
			font-size: 12px;
			width: 440px;
			margin: 0 5px;
			line-height: 37px;
		}
	
		.menu {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			
			.menu_item {
				margin: 10px 10px;
				color: #000;
				text-decoration: none;
				font-size: 12px;
				
				&:hover {
					cursor: pointer;
				}
			}
	
		}
	}
}
@media screen and (min-width: 768px) {
	.header {
		width: 100%;
		height: auto;
		border-bottom: solid 1px #999;
		display: flex;
		flex-basis: auto;
	
		.title {
			color: #000;
			font-weight: 600;
			font-size: 24px;
			width: 440px;
			margin: 5px;
		}
	
		.menu {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			
			.menu_item {
				margin: 10px 10px;
				color: #000;
				text-decoration: none;
				
				&:hover {
					cursor: pointer;
				}
			}
	
		}
	}
}
