.footer {
    width: 100%;
    position: absolute;
    bottom: 0; 
    border-top: solid 1px #999;

    .footer_contents{
        margin: 20px auto;
        color: #777;
    }
}