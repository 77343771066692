
.login_container {
	margin: 200px auto;

	.login_title {
		margin: 50px 30%;
	}
	
	.login_form_container {
		margin: 10px 5%;

		.submit_button{
			margin: 30px auto;
		}
	}
}